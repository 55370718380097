
import { queryVendorList, basebu, queryTterraceList, userList, qmsUserList, getCargoOwner, cargoByVendor, warehouseList, getWarehouse, listAllEmployeeApi, listLaunchSeasonAPi } from '@/api/listSelection'
import { listByPlatform, orderCodeList, frameCodeList, position, StockChannelList, getPhysicsWarehouseList, getDsbVersion, usersList } from '@/api/scm-api'
import { queryStyleList, queryColorList, querySizeList, querylocationList, innerOrganization, organizationSealtypes, queryBrandList } from '@/api/listSelection'
import { taskUsersList } from '@/api/user'
import { listByPrincipalConfig } from '@/api/configuration'

export default {
  getPhysicsWarehouseList: {
    api: getPhysicsWarehouseList,
    configuration: {
      key: 'logicWarehouseCode',
      label: 'warehouseName',
      value: 'warehouseName'
    }
  },
  StockChannelList: {
    api: StockChannelList,
    configuration: {
      key: 'channelId',
      label: 'channelName',
      value: 'channelId'
    }
  },
  vendorList: {
    api: queryVendorList,
    configuration: {
      key: 'id',
      label: 'vendorName',
      value: 'id'
    }
  },
  styleList: {
    api: queryStyleList,
    configuration: {
      key: 'id',
      label: 'styleName',
      value: 'id'
    }
  },
  colorList: {
    api: queryColorList,
    configuration: {
      key: 'id',
      label: 'colorName',
      value: 'colorName'
    }
  },
  sizeList: {
    api: querySizeList,
    configuration: {
      key: 'id',
      label: 'size',
      value: 'size'
    }
  },
  userList: {
    api: taskUsersList,
    configuration: {
      key: 'id',
      label: 'username',
      value: 'id'
    }
  },
  locationList: {
    api: querylocationList,
    configuration: {
      key: 'locationCode',
      label: 'locationCode',
      value: 'locationCode'
    }
  },
  buList: {
    api: basebu,
    configuration: {
      key: 'id',
      label: 'buName',
      value: 'buName'
    }
  },
  platformList: {
    api: queryTterraceList,
    configuration: {
      key: 'id',
      label: 'platformName',
      value: 'id'
    }
  },
  siteList: {
    api: listByPlatform,
    configuration: {
      key: 'id',
      label: 'siteName',
      value: 'siteCode'
    }
  },
  newUserList: {
    api: userList,
    configuration: {
      key: 'id',
      label: 'username',
      value: 'username'
    }
  },
  qmsUserList: {
    api: qmsUserList,
    configuration: {
      key: 'userId',
      label: 'name',
      value: 'userId'
    }
  },
  platformListConfig: {
    api: listByPrincipalConfig,
    configuration: {
      key: 'id',
      label: 'platformName',
      value: 'platformName'
    }
  },
  organization: {
    api: innerOrganization,
    configuration: {
      key: 'organizationCode',
      label: 'organizationName',
      value: 'organizationCode'
    }
  },
  sealtype: {
    api: organizationSealtypes,
    configuration: {
      key: 'sealTypeCode',
      label: 'sealTypeName',
      value: 'sealTypeCode'
    }
  },
  cargoOwnerList: {
    api: getCargoOwner,
    configuration: {
      key: 'id',
      label: 'cargoOwnerName',
      value: 'id'
    }
  },
  orderCodeList: {
    api: orderCodeList,
    configuration: {
      key: 'id',
      label: 'purchaseOrderContractCode',
      value: 'purchaseOrderContractCode'
    }
  },
  frameCodeList: {
    api: frameCodeList,
    configuration: {
      key: 'id',
      label: 'purchaseFrameworkContractCode',
      value: 'purchaseFrameworkContractCode'
    }
  },
  cargoByVendor: {
    api: cargoByVendor
  },
  warehouseList: {
    api: warehouseList,
    configuration: {
      key: 'id',
      label: 'warehouseName',
      value: 'sourceWarehouseCode'
    }
  },
  getWarehouse: {
    api: getWarehouse,
    configuration: {
      key: 'id',
      label: 'warehouseName',
      value: 'sourceWarehouseCode'
    }
  },
  position: {
    api: position,
    configuration: {
      key: 'stylePositionId',
      label: 'position',
      value: 'stylePositionId'
    }
  },
  listAllEmployee: {
    api: listAllEmployeeApi,
    configuration: {
      key: 'id',
      label: 'enName',
      value: 'enName'
    }
  },
  UsersList: {
    api: usersList,
    configuration: {
      key: 'id',
      label: 'username',
      value: 'id'
    }
  },
  brandList: {
    api: queryBrandList,
    configuration: {
      key: 'id',
      label: 'brandName',
      value: 'id'
    }
  },
  listLaunchSeason: {
    api: listLaunchSeasonAPi,
    configuration: {
      key: 'id',
      label: 'dictCnName',
      value: 'dictValue'
    }
  },
  getDsbVersionAPI: {
    api: getDsbVersion,
    configuration: {
      key: 'versionCode',
      label: 'versionCode',
      value: 'versionCode'
    }
  }

}
