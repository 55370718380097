import requestWms from '@/utils/request'
import request from '@/utils/scm-request'

export function insertPrincipal(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/insertPrincipal`,
    method: 'post',
    data
  })
}

export function pagePrincipalList(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/pagePrincipalList`,
    method: 'post',
    data
  })
}

export function updateOperationPrincipal(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/updateOperationPrincipal`,
    method: 'post',
    data
  })
}

export function updatePlanPrincipal(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/updatePlanPrincipal`,
    method: 'post',
    data
  })
}

export function updatePrincipalBatch(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/updatePrincipalBatch`,
    method: 'post',
    data
  })
}

export function exportPrincipalTemplate() {
  return requestWms({
    url: `/base/approvalNoticeConfig/exportPrincipalTemplate`,
    method: 'get',
    responseType: 'arraybuffer'
  })
}

export function importPrincipalView(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/importPrincipalView`,
    method: 'post',
    data
  })
}

export function importPrincipal(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/importPrincipal`,
    method: 'post',
    data
  })
}

export function approval(params) {
  return requestWms({
    url: `/base/approvalNoticeConfig/approval`,
    method: 'get',
    params
  })
}

export function notice(params) {
  return requestWms({
    url: `/base/approvalNoticeConfig/notice`,
    method: 'get',
    params
  })
}

export function upsertApproval(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/upsertApproval`,
    method: 'post',
    data
  })
}
export function roleSelect(params) {
  return requestWms({
    url: `/base/user/roleSelect`,
    method: 'get',
    params
  })
}
export function upsertNotice(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/upsertNotice`,
    method: 'post',
    data
  })
}

export function listByPrincipalConfig(params) {
  return requestWms({
    url: `/base/platformsiteshop/listByPrincipalConfig`,
    method: 'get',
    params
  })
}

export function styleListConfig(params) {
  return requestWms({
    url: `/base/basestyle/listByPrincipalConfig`,
    method: 'get',
    params
  })
}

export function buListConfig(params) {
  return requestWms({
    url: `/base/basebu/listByPrincipalConfig`,
    method: 'get',
    params
  })
}

export function pageSynPrincipalList(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/pageSynPrincipalList`,
    method: 'post',
    data
  })
}

export function exportSynPrincipalTemplate() {
  return requestWms({
    url: `/base/approvalNoticeConfig/exportSynPrincipalTemplate`,
    method: 'get',
    responseType: 'arraybuffer'
  })
}

export function importSynPrincipal(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/importSynPrincipal`,
    method: 'post',
    data
  })
}

export function importSynPrincipalView(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/importSynPrincipalView`,
    method: 'post',
    data
  })
}

export function delSynPrincipal(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/delSynPrincipal`,
    method: 'post',
    data
  })
}

export function insertSynPrincipal(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/insertSynPrincipal`,
    method: 'post',
    data
  })
}

export function synByHand() {
  return requestWms({
    url: `/base/approvalNoticeConfig/synByHand`,
    method: 'get'
  })
}

export function listSyncLog(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/listSyncLog`,
    method: 'post',
    data
  })
}

export function updatePlan(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/updatePlan`,
    method: 'post',
    data
  })
}

export function updateOperator(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/updateOperator`,
    method: 'post',
    data
  })
}

export function getBuByStyle(params) {
  return requestWms({
    url: `/base/basebu/getBuByStyle`,
    method: 'get',
    params
  })
}

export function respConfigDelete(data) {
  return requestWms({
    url: `/base/approvalNoticeConfig/delete`,
    method: 'post',
    data
  })
}
export function getBasecategory(params) {
  return requestWms({
    url: `/base/basecategory/tree`,
    method: 'get',
    params
  })
}

export function listByPage(params, data) {
  return request({
    url: `/scm-purchase/productSymbol/listByPage`,
    method: 'post',
    data,
    params
  })
}

export function getBaseEnablecategory(params) {
  return requestWms({
    url: `/base/basecategory/treeEnable`,
    method: 'get',
    params
  })
}

// moq配置
// 获取生效中列表
export function pageEffective(params, data) {
  return request({
    url: `/scm-purchase/dsb/moq/pageEffective`,
    method: 'post',
    params,
    data
  })
}

export function ImportFileView(data) {
  return request({
    url: `/scm-purchase/productSymbol/preImport`,
    method: 'post',
    data
  })
}

export function importCommit(data) {
  return request({
    url: `/scm-purchase/productSymbol/importCommit`,
    method: 'post',
    data,
    timeout: 9999999
  })
}

export function delSymbol(data) {
  return request({
    url: `/scm-purchase/productSymbol/delSymbol`,
    method: 'post',
    data
  })
}

export function pageWait(data) {
  return request({
    url: `/scm-purchase/dsb/moq/pageWait`,
    method: 'post',
    data
  })
}

export function exportProductSymbol(data) {
  return request({
    url: `/scm-purchase/productSymbol/exportProductSymbol`,
    method: 'post',
    data
  })
}

export function moqDetail(params) {
  return request({
    url: `/scm-purchase/dsb/moq/detail`,
    method: 'post',
    params
  })
}

export function statsPoPageList(params, data) {
  return request({
    url: `/scm-purchase/dsb/report/stats/po`,
    method: 'post',
    data,
    params
  })
}

// 新增moq
export function moqSave(data) {
  return request({
    url: `/scm-purchase/dsb/moq/save`,
    method: 'post',
    data
  })
}

export function exportStatusPo(data) {
  return request({
    url: `/scm-purchase/dsb/report/export/stats/po`,
    method: 'post',
    data
  })
}

// 导入moq预览
export function moqPreview(data) {
  return request({
    url: `/scm-purchase/dsb/moq/preview`,
    method: 'post',
    data
  })
}

export function statsTranferPageList(params, data) {
  return request({
    url: `/scm-purchase/dsb/report/stats/transfer/page`,
    method: 'post',
    data,
    params
  })
}

export function exportStatusTransfer(data) {
  return request({
    url: `/scm-purchase/dsb/report/stats/transfer/export`,
    method: 'post',
    data
  })
}

// 提交moq预览
export function moqImportData(data) {
  return request({
    url: `/scm-purchase/dsb/moq/importData`,
    method: 'post',
    data
  })
}

export function statsFcstPageList(params, data) {
  return request({
    url: `/scm-purchase/dsb/report/fcst/po`,
    method: 'post',
    data,
    params
  })
}

export function exportStatusFcst(data) {
  return request({
    url: `/scm-purchase/dsb/report/export/fcst/po`,
    method: 'post',
    data
  })
}

// moq批量导入模板下载
export function moqImportTemplate() {
  return request({
    url: `/scm-purchase/dsb/moq/template`,
    method: 'get'
  })
}

// moq导出
export function moqExportData(data) {
  return request({
    url: `/scm-purchase/dsb/moq/export`,
    method: 'post',
    data
  })
}

export function tranferVersionList(data) {
  return request({
    url: `/scm-purchase/dsb/report/stats/transfer/versionList`,
    method: 'post',
    data
  })
}

// 商品供应及缺货统计列表查询-备货渠道维度
export function outOfStockChannelPage(params, data) {
  return request({
    url: `/scm-purchase/dsb/report/outOfStockChannel/page`,
    method: 'post',
    params,
    data
  })
}

// 商品供应及缺货统计列表查询-全局维度
export function outOfStockPage(params, data) {
  return request({
    url: `/scm-purchase/dsb/report/outOfStock/page`,
    method: 'post',
    params,
    data
  })
}

// 商品供应及缺货统计导出-全局维度
export function exportOutOfStockChannel(data) {
  return request({
    url: `/scm-purchase/dsb/report/export/outOfStockChannel`,
    method: 'post',
    data
  })
}

// 商品供应及缺货统计导出-全局维度
export function exportOutOfStock(data) {
  return request({
    url: `/scm-purchase/dsb/report/export/outOfStock`,
    method: 'post',
    data
  })
}

// 商品供应及缺货统计-全局维度重新计算
export function recalculate(data) {
  return request({
    url: `/scm-purchase/dsb/report/outOfStock/recalculate`,
    method: 'post',
    data
  })
}

// 商品供应及缺货统计-备货渠道重新计算
export function recalculateChannel(data) {
  return request({
    url: `/scm-purchase/dsb/report/outOfStock/recalculateChannel`,
    method: 'post',
    data
  })
}

// 缺货原因预览分页(前端分页展示用)
export function shortageCauseView(data) {
  return request({
    url: `/scm-purchase/dsb/statistics/goodsSupplyShortage/shortageCauseView`,
    method: 'post',
    data
  })
}

// 缺货原因预览
export function shortageCauseViewByPage(data) {
  return request({
    url: `/scm-purchase/dsb/statistics/goodsSupplyShortage/shortageCauseViewByPage`,
    method: 'post',
    data,
    timeout: 9999999
  })
}

// 分析提交
export function shortageCauseSubmit(data) {
  return request({
    url: `/scm-purchase/dsb/statistics/goodsSupplyShortage/shortageCauseSubmit`,
    method: 'post',
    data
  })
}

// 库存数据统计
export function statsInventoryPageList(params, data) {
  return request({
    url: `/scm-purchase/dsb/report/stats/inventory`,
    method: 'post',
    data,
    params
  })
}

export function exportInventory(data) {
  return request({
    url: `/scm-purchase/dsb/report/export/inventory`,
    method: 'post',
    data
  })
}

// 库存数据统计版本下拉数据
export function inventoryVersion() {
  return request({
    url: `/scm-purchase/basic/inventoryVersion`,
    method: 'get'
  })
}

export function accuracyStatisticsSkuPageList(params, data) {
  return request({
    url: `/scm-purchase/salePlan/accuracy/statistics/sku`,
    method: 'post',
    data,
    params
  })
}

export function accuracyStatisticsStylePageList(params, data) {
  return request({
    url: `/scm-purchase/salePlan/accuracy/statistics/style`,
    method: 'post',
    data,
    params
  })
}

export function turnOverPageList(data) {
  return request({
    url: `/scm-purchase/dsb/report/stats/turnOver`,
    method: 'post',
    data
  })
}

export function AlarmConfigList(data) {
  return request({
    url: `/scm-purchase/AlarmConfig/list`,
    method: 'post',
    data
  })
}

export function AlarmConfigUpdate(data) {
  return request({
    url: `/scm-purchase/AlarmConfig/update`,
    method: 'post',
    data
  })
}

export function listAlarmRateEnumALl() {
  return request({
    url: `/scm-purchase/AlarmConfig/listAlarmRateEnumALl`,
    method: 'get'
  })
}

export function listAlarmMethodEnumAll() {
  return request({
    url: `/scm-purchase/AlarmConfig/listAlarmMethodEnumAll`,
    method: 'get'
  })
}
