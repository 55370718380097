<template>
  <el-select
    ref="select"
    :value="value"
    :clearable="clearable"
    filterable
    :placeholder="placeholder.length ? placeholder : $t('page.selectPlaceholder')"
    :disabled="disabled"
    :multiple="multiple"
    :filter-method="filterMethod"
    :collapse-tags="collapseTags"
    :loading="loading"
    :size="size.length ? size : $store.getters.size"
    @visible-change="visibleChangeEvent"
    @input="input($event)"
    @change="change"
    @focus="$emit('focus', $event)"
  >
    <el-option
      v-for="item in options"
      :key="item[elOptionConfiguration.key]"
      :value-key="item[elOptionConfiguration.key]"
      :label="item[elOptionConfiguration.label] || item[elOptionConfiguration.value] "
      :value="item[elOptionConfiguration.value]"
    />
  </el-select>
</template>

<script>

import apiMapList from './apiMapList'
import { isEqual } from 'lodash'
export default {
  name: 'NormSelection',
  props: {
    // 双向绑定的值 动态修改类型为 Array Object 会报错 <got null>，故解除required true
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    // 是否禁用
    disabled: {
      type: Boolean
    },
    // 是否使用传入的options 有则使用
    selectOptions: {
      type: Array,
      default: () => []
    },
    // 需要调用的接口
    apiKey: {
      type: String,
      default: ''
    },
    // {value: 选项的值, label: 选项的标签，若不设置则默认与 value 相同, key: 唯一值}
    configuration: {
      type: Object,
      default: () => ({})
    },
    // 调用接口所需参数
    params: {
      type: Object,
      default: () => ({})
    },
    // 是否显示清空按钮
    clearable: {
      type: Boolean,
      default: true
    },
    // 是否需要级联
    ganged: {
      type: Boolean,
      default: false
    },
    // 级联需要忽略的参数
    gangedIgnore: {
      type: Array,
      default: () => []
    },
    // 级联前置条件不足时显示的消息
    gangedPrompt: {
      type: String,
      default: ''
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    collapseTags: {
      type: Boolean,
      default: true
    },
    // 无需确认是否传入参数即可调用
    notRequiredParams: {
      type: Boolean,
      default: false
    },
    // 是否需要默认选择
    isDefault: {
      type: Boolean,
      default: false
    },
    // 忽略的选项
    ignoreList: {
      type: Array,
      default: () => []
    },
    // 数据处理
    dataMethod: {
      type: Function,
      default: void function() {}
    },
    placeholder: {
      type: String,
      default: ''
    },
    stamp: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    },
    // 再次请求
    queryFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: [],
      requestData: [],
      selectConfiguration: {
        key: 'val',
        value: 'val',
        label: 'label'
      },
      loading: false
    }
  },
  computed: {
    // 获取当前 Configuration
    elOptionConfiguration() {
      return Object.keys(this.configuration).length !== 0 ? this.configuration : this.selectConfiguration
    },
    // 是否传入了参数
    isCommitParams() {
      return Object.keys(this.params).every(key => {
        // 是否是级联需要忽略的参数
        const isGangedIgnore = this.gangedIgnore.includes(key)
        //  是否存在params
        const isExist = Array.isArray(this.params[key]) ? this.params[key].length !== 0 : !!this.params[key]
        return isGangedIgnore || isExist
      }) && Object.keys(this.params).length !== 0
    },
    // 是否显示 gangedPrompt
    isVisibleToast() {
      return this.ganged && this.requestData.length === 0 && this.options.length === 0 && this.gangedPrompt !== '' && !this.isCommitParams
    },
    // 含默认选择的select list
    defaultSelectOptions() {
      if (!this.isDefault) return []
      const selectValue = this.value
      const configValue = this.selectConfiguration.value
      const options = this.requestData
      const defaultItems = this.requestData.filter(item => {
        if (Array.isArray(selectValue)) {
          return selectValue.some(val =>
            Object.is(String(val), String(item[configValue]))
          )
        } else {
          return Object.is(String(selectValue), String(item[configValue]))
        }
      })
      return defaultItems.concat(options).reduce((acc, cur) => {
        if (acc.some(item => item[configValue] === cur[configValue])) {
          return acc
        } else {
          acc.push(cur)
          return acc
        }
      }, [])
    }
  },
  watch: {
    params: {
      handler(val, lodVal) {
        if (isEqual(val, lodVal)) return
        if (this.ganged) {
        // 传入了参数（除去忽略）或 无需传入参数
          if (this.isCommitParams || this.notRequiredParams) {
            this._queryOptions()
          } else {
            this.requestData = []
            this.options = []
          }
        }
      },
      deep: true
    },
    selectOptions(list) {
      if (list.length > 0) {
        this.requestData = this.selectOptions
        const options = Array.isArray(this.requestData) ? this.requestData : []
        this.options = this.dataMethod ? this.dataMethod(options) : options
      }
    },
    'queryFlag'(val) {
      if (val) {
        this._queryOptions()
      }
    }

  },
  mounted() {
    // 未传入参数并且无级联 或 无需参数
    if (this.isCommitParams || this.notRequiredParams || !this.ganged) {
      this._queryOptions()
    }
    if (this.width && this.$refs['select'].$children[0] && this.$refs['select'].$children[0].$el.children[0]) {
      const symbols = ['px', 'vh', 'vw', 'em', 'rem']
      const width = symbols.some(item => this.width.includes(item)) ? this.width : this.width + 'px'
      this.$refs['select'].$children[0].$el.children[0].style.width = width
    }
  },
  methods: {
    filterMethod(query) {
      if (query === '') {
        this.$nextTick(() => {
          const options = this.requestData
          this.options = this.dataMethod
            ? this.dataMethod(options)
            : options
        })
      } else {
        const result = []
        const queryUppercase = query.toLocaleUpperCase()
        this.requestData.forEach(item => {
          const locationCodeUppercase = item[this.elOptionConfiguration.label]?.toLocaleUpperCase()
          if (locationCodeUppercase?.includes(queryUppercase)) result.push(item)
        })
        const options = result
        this.options = this.dataMethod
          ? this.dataMethod(options)
          : options
      }
    },
    input(val) {
      this.$emit('input', val)
    },
    change(val) {
      this.$emit('change', val)
    },
    async _queryOptions() {
      const { api, configuration } = apiMapList[this.apiKey] || {}
      if (this.selectOptions.length || !api) {
        this.requestData = this.selectOptions
        const options = this.requestData
        this.options = this.dataMethod
          ? this.dataMethod(options)
          : options
      } else {
        this.selectConfiguration = configuration
        try {
          this.loading = true
          const { datas } = await api(this.params)
          this.requestData = datas.filter(item => !this.ignoreList.includes(item[this.selectConfiguration.value]))
          this.$emit('responseData', datas)
          const options = this.isDefault ? this.defaultSelectOptions : this.requestData
          this.options = this.dataMethod
            ? this.dataMethod(options)
            : options
        } finally {
          this.loading = false
        }
      }
    },
    visibleChangeEvent(visible) {
      this.$emit('visible-change', visible)
      if (visible) {
        if (this.isVisibleToast) {
          this.$message.warning(this.gangedPrompt)
        }
      } else {
        this.filterMethod('')
      }
    }
  }
}
</script>
